import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { DataProviderWrapper } from "./style/DataProviderWrapper";
import API from "../../api/axios";
import { APP_URLS } from "../../api/url";
import { toast } from "react-toastify";
import RecordCount from "../../assets/SharedComponents/RecordCount/RecordCount";
import { Button, Tooltip } from "@mui/material";
import {
  FaCompany,
  FaDownArrow,
  FaFilterIcon,
  FaFluentIcon,
  FaUpArrow,
  FaUser,
} from "../../assets/Images/icon";
import edit from "../../assets/Images/image/edit.png";
import { ThreeDots } from "react-loader-spinner";
import NoRecord from "../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../assets/SharedComponents/Pagination";
import getUniqueObjects from "../../core/utils/getUniqueObjects.js/getUniqueObjects";
import moment from "moment";
import {
  DEBOUNCE_TIME,
  FLAGGED_REVIEW_STATUS,
  MAPPED_VALUES,
  PROVIDERS,
  userTypeName,
} from "../../config/constant";
import Popup from "./components/Popup";
import { redirect } from "../../core/utils/redirect/redirect";
import StaticFilter from "../../assets/SharedComponents/Filters/StaticFilter";
import Sorting from "../../assets/SharedComponents/Sorting/Sorting";
import Unassign from "./components/Unassign";
import ChangeStatus from "./components/ChangeStatus";
import download from "downloadjs";
import ExtractReport from "../../assets/SharedComponents/ExtractReport/ExtractReport";
import { capitalizeFirstLetter } from "../../core/utils";

const providerStatusList = Object.keys(MAPPED_VALUES).map((key) => ({
  name: key,
  value: MAPPED_VALUES[key],
}));

const DataProvider = () => {
  const navigate = useNavigate();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchkeyword, _searchkeyword] = useState("");
  const [countValue, _countValue] = useState("");
  const [page, setPage] = useState(1);
  const [loaded, _loaded] = useState(false);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  const [assignData, setAssignData] = useState({
    data: [],
    loading: false,
    error: "",
    totalPage: 1,
    currentPage: 1,
  });
  const [assignSearch, setAssignSearch] = useState(null);

  const [data, _data] = useState([]);
  const [providerId, setProviderId] = useState(1);
  const [mapped, setMapped] = useState(MAPPED_VALUES.Assigned);
  const [filters, _filters] = useState(false);
  const [sort, _sort] = useState("-created_at");
  const [popup, _popup] = useState(false);
  const [isAssigned, _isAssigned] = useState(false);
  const [dealerWithActionType, _dealerWithActionType] = useState({
    id: null,
    type: "",
  });
  const searchParamsValues = useMemo(() => {
    const searchkeyword = searchParams.get("a_keyword") || "";
    const countValue = searchParams.get("count") || "";
    const page =
      searchParams.get("page") !== null
        ? parseInt(searchParams.get("page"))
        : 1;
    const mapped = searchParams.get("mapped") || MAPPED_VALUES.Assigned;
    const providerId = searchParams.get("provider_id") || 1;
    return {
      searchkeyword,
      countValue,
      page,
      mapped,
      providerId,
    };
  }, [searchParams]);

  useEffect(() => {
    _searchkeyword(searchParamsValues.searchkeyword);
    _countValue(searchParamsValues.countValue);
    setPage(searchParamsValues.page);
    setMapped(searchParamsValues.mapped);
    setProviderId(searchParamsValues.providerId);
  }, [searchParamsValues]);

  useEffect(() => {
    getProvidersData();
  }, [page, countValue, providerId, mapped, sort, isAssigned]);

  useEffect(() => {
    getAssignData();
  }, [assignData?.currentPage]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (assignSearch !== null) getAssignData(true);
    }, DEBOUNCE_TIME);
    return () => clearTimeout(getData);
  }, [assignSearch]);

  const getAssignData = (isSearching = false) => {
    API.get(
      `${APP_URLS.ASSIGN_DATA}?per_page=${25}&page=${
        assignData.currentPage
      }&keyword=${assignSearch || ""}`
    )
      .then((res) => {
        const resp = res.data;
        if (resp.success) {
          let data = resp.data.data;
          if (!isSearching)
            data = getUniqueObjects([...assignData.data, ...data]);
          setAssignData({
            ...assignData,
            data: data,
            currentPage: resp.data.current_page,
            error: "",
            totalPage: resp.data.last_page,
            loading: false,
          });
        } else {
          setAssignData({
            ...assignData,
            loading: false,
            error: resp.message,
          });
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        setAssignData({
          ...assignData,
          loading: false,
          error: error_message,
        });
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const getProvidersData = () => {
    _loaded(true);
    let urlParams = {
      page: page,
      per_page: countValue,
      "filter[provider_id]": providerId || "",
      "filter[mapped]": mapped || 0,
    };
    let restUrl = new URLSearchParams(urlParams);
    API.get(APP_URLS.DATA_PROVIDERS + `?${restUrl}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  // handle search and per page data changes
  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if (
      (func === _countValue && searchValue !== countValue) ||
      (func === _searchkeyword && searchValue !== searchkeyword)
    ) {
      if (searchValue !== "") {
        _totalPage(0);
        setPage(1);
      }
    }
  };

  const clearFilter = () => {
    _filters(true);
    _sort("-created_at");
    setMapped(MAPPED_VALUES.Assigned);
    setProviderId(1);
    if (countValue !== "") {
      _totalPage(0);
      setPage(1);
    }
    for (var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
    }
  };

  const handleMapChange = (type) => {
    _totalPage(0);
    setPage(1);
    setMapped(type);
  };
  const handleProviderChange = (id) => {
    _totalPage(0);
    setPage(1);
    setProviderId(id);
  };
  const setsorting = (val) => {
    _sort(val);
    if (sort !== val) {
      setPage(1);
      _totalPage(0);
    }
  };

  const navigationUrl =
    "/data-providers-errors?" +
    (mapped !== "" ? "mapped=" + mapped + "&" : "") +
    (providerId !== "" ? "provider_id=" + providerId + "&" : "") +
    (countValue !== "" ? "count=" + countValue + "&" : "") +
    (page !== "" ? "page=" + page : "");

  const handleExtractReport = () => {
    let urlParams = {
      page: page,
      per_page: countValue,
      "filter[provider_id]": providerId || "",
      "filter[mapped]": mapped || 0,
    };
    let restUrl = new URLSearchParams(urlParams);
    API.get(APP_URLS.DATA_PROVIDERS + `/download_excel_file?${restUrl}`, {
      responseType: "blob",
    })
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          toast.success("Downloading. Please wait", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const content = res.headers["content-type"];
          const currentDate = moment().format("YYYY-MM-DD HH-mm-ss");
          const fileName = `${currentDate}_data_providers_report.xlsx`;
          download(resp, fileName, content);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(async function (error) {
        const resp = error.response;

        let error_message = "Something went wrong!";

        if (resp && resp.data instanceof Blob) {
          try {
            // Convert Blob to JSON
            const text = await resp.data.text();
            const json = JSON.parse(text);

            if (json.errors) {
              error_message = Object.values(json.errors)[0][0] || error_message;
            } else if (json.data?.error) {
              error_message = json.data.error;
            } else if (json.error) {
              error_message = json.error;
            } else {
              error_message = json.message || error_message;
            }
          } catch (err) {}
        } else if (resp?.data?.message) {
          error_message = resp.data.message;
        }

        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <DataProviderWrapper>
      <div className="auctionMainContainer">
        <div className="row mt-4">
          <div className="col-md-4 offset-md-4 mb-2">
            <h1> Data Provider</h1>
          </div>
        </div>
        <div className="justify-content-end mb-2 d-flex gap-2 mb-2 select-info align-items-center">
          <StaticFilter
            data={providerStatusList}
            handleChange={(e) => {
              handleMapChange(e.target.value);
            }}
            value={mapped}
          />
          <StaticFilter
            data={PROVIDERS}
            handleChange={(e) => {
              handleProviderChange(e.target.value);
            }}
            value={providerId}
          />
          <div className="select-wrapper">
            <RecordCount
              onSearchClick={(search) =>
                handleSearchChange(_countValue, search)
              }
              id="pageCount"
              val={countValue}
              filters={filters}
              _filters={_filters}
            />
          </div>
          <ExtractReport
            downloadFunction={handleExtractReport}
            styles="extract-report ml-2"
            text=" Extract report"
          />
          <Tooltip title="Clear Filter" placement="bottom">
            <FaFilterIcon
              className="filter-icon"
              role="button"
              onClick={clearFilter}
            />
          </Tooltip>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between info-wrap">
        <div className="justify-content-end mt-2 me-2">
          <span className="text-start">
            Total Records : <b>{total}</b>
          </span>
        </div>
        <div className="d-flex gap-3">
          <Link to="/volkswagen" className="navigatingLink" variant="contained">
            Volkswagen
          </Link>
          <Link
            to="/advertisers"
            className="navigatingLink"
            variant="contained"
          >
            Show Advertisers
          </Link>
          <Link to={navigationUrl} className="navigatingLink">
            Show Errors
          </Link>
        </div>
      </div>
      <div className="tableContainer">
        <table className="auctionTable reviewTable">
          <thead className="tableHead">
            <tr className="table-row">
              <th className="time-start"> FileName</th>
              <th>Provider</th>
              <th className="longText">{`${userTypeName.DEALER} Details`}</th>
              <th className="time-start"> {`User/${userTypeName.DEALER}`} </th>
              <th className="time-start">
                Last Synced
                <Sorting
                  sortType="created_at"
                  sort={sort}
                  handleSort={setsorting}
                />
              </th>
              <th>Subscription Status</th>
              <th className="action-wrap">Action</th>
            </tr>
          </thead>
          {loaded ? (
            <div className="d-flex justify-content-center three-dot">
              <ThreeDots
                height="150"
                width="150"
                radius="9"
                color="#637df4"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <tbody>
              {data.length > 0 ? (
                data.map((item) => {
                  return (
                    <tr className={"table-row"} key={item.id}>
                      <td>{item.filename}</td>
                      <td>
                        <span>
                          {
                            PROVIDERS.find((i) => i.value == item?.provider_id)
                              ?.name
                          }
                        </span>
                      </td>
                      <td className="longText ">
                        <span>
                          {item.dealer_detail?.company_name ? (
                            <>
                              <b>{userTypeName.DEALER}: </b>
                              {item.dealer_detail?.company_name}{" "}
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                        <br />
                        <span>
                          <b>Address: </b>{" "}
                          {item.dealer_detail?.street ? (
                            <> {item.dealer_detail.street}</>
                          ) : (
                            ""
                          )}
                          {item.dealer_detail?.city
                            ? item.dealer_detail.city + ", "
                            : ""}
                          {item.dealer_detail?.country
                            ? item.dealer_detail.country + ", "
                            : ""}
                          {item.dealer_detail?.zip
                            ? " - " + item.dealer_detail.zip
                            : ""}
                        </span>
                        <br />
                        <span>
                          {item.dealer_detail?.website ? (
                            <>
                              <b>Website: </b>
                              {item.dealer_detail.website}{" "}
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                        <br />
                        <span>
                          {item.dealer_detail?.email ? (
                            <>
                              <b>Email: </b>
                              {item.dealer_detail.email}{" "}
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                        <br />
                        <span>
                          {item?.dealer_detail?.phone_no ? (
                            <>
                              <b>Phone No: </b>
                              {Array.isArray(item.dealer_detail?.phone_no)
                                ? item.dealer_detail.phone_no.join(", ")
                                : item.dealer_detail.phone_no}
                            </>
                          ) : (
                            ""
                          )}
                        </span>
                        <br />
                      </td>
                      <td
                        onClick={() => {
                          redirect(item);
                        }}
                        className=" link "
                      >
                        <b className="d-flex align-items-center">
                          {item?.user_id ? (
                            <>
                              {item?.user?.name}
                              <FaUser />
                            </>
                          ) : item.subsidiary_id ? (
                            <>
                              {item.subsidiary.slug} <FaCompany />{" "}
                            </>
                          ) : (
                            "-"
                          )}
                        </b>
                      </td>
                      <td className="">
                        {item.last_synced
                          ? moment(item.last_synced)
                              .tz("Europe/Amsterdam")
                              .format("DD-MM-YYYY HH:mm")
                          : "-"}
                      </td>
                      <td>
                        <span
                          className={
                            item?.subsidiary?.subscription_status === "active"
                              ? "active-status"
                              : "progress-status"
                          }
                        >
                          {capitalizeFirstLetter(
                            item?.subsidiary?.subscription_status ?? "-"
                          )}
                        </span>
                      </td>

                      <td className="action-wrap d-flex">
                        {mapped != 0 ? (
                          <div className="action-btns">
                            <Button
                              onClick={() => {
                                _popup(true);
                                _dealerWithActionType({
                                  id: item.id,
                                  type: "re_assign",
                                });
                              }}
                              variant="contained"
                            >
                              Re-Assign
                            </Button>
                            <ChangeStatus
                              id={item.id}
                              isActive={item.active}
                              _isAssigned={_isAssigned}
                            />
                            <Unassign id={item.id} _isAssigned={_isAssigned} />
                          </div>
                        ) : (
                          <Button
                            onClick={() => {
                              _popup(true);
                              _dealerWithActionType({
                                id: item.id,
                                type: "assign",
                              });
                            }}
                            variant="contained"
                            sx={{ textTransform: "none", fontWeight: "600" }}
                          >
                            Assign
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoRecord />
              )}
            </tbody>
          )}
        </table>
      </div>
      <div>
        {totalPage > 1 ? (
          <Pagination
            totalPages={totalPage}
            page={page}
            key={totalPage}
            onPageClick={(page) => setPage(page + 1)}
          />
        ) : (
          ""
        )}
      </div>
      {popup && (
        <Popup
          setPage={setPage}
          search={assignSearch}
          setSearch={setAssignSearch}
          data={assignData?.data || []}
          hasMore={assignData?.totalPage > assignData?.currentPage}
          loading={assignData?.loading}
          error={assignData?.error}
          _popup={_popup}
          setData={setAssignData}
          _isAssigned={_isAssigned}
          dealerWithActionType={dealerWithActionType}
        />
      )}
    </DataProviderWrapper>
  );
};

export default DataProvider;
