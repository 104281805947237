import React, { useEffect, useRef, useState } from "react";
import API from "../../api/axios";
import { APP_URLS } from "../../api/url";
import { toast } from "react-toastify";
import RecordCount from "../../assets/SharedComponents/RecordCount/RecordCount";
import { Button, Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import NoRecord from "../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../assets/SharedComponents/Pagination";
import { userTypeName, userTypes } from "../../config/constant";
import MessangerWrapper from "./style/MessangerWrapper";
import SearchKey from "../../assets/SharedComponents/Search/SearchKey";
import StaticFilter from "../../assets/SharedComponents/Filters/StaticFilter";
import Sorting from "../../assets/SharedComponents/Sorting/Sorting";
import UserFilter from "../../assets/SharedComponents/Filters/UserFilter";
import SubsidiaryFilter from "../../assets/SharedComponents/Filters/SubsidiaryFilter";
import CountryFilter from "../../assets/SharedComponents/Filters/CountryFilter";
import moment from "moment";
import { formatTime } from "../../core/utils";
import { NavLink } from "react-router-dom";
import download from "downloadjs";
import ExtractReport from "../../assets/SharedComponents/ExtractReport/ExtractReport";
const userTypesData = Object.keys(userTypes).map((key) => ({
  name: key,
  value: userTypes[key],
}));
const Messanger = () => {
  const day = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    .toJSON()
    .slice(0, 10);
  const [searchkeyword, _searchkeyword] = useState("");
  const [countValue, _countValue] = useState("");
  const [page, setPage] = useState(userTypes.Company);
  const [userID, _userID] = useState("");
  const [companyId, _companyId] = useState("");
  const [loaded, _loaded] = useState(false);
  const [totalPage, _totalPage] = useState(0);
  const [data, _data] = useState([]);
  const [filters, _filters] = useState(false);
  const [sort, _sort] = useState("");
  const [userType, _UserType] = useState(1);
  const [isUnread, _isUnread] = useState(false);
  const [country, setCountry] = useState("");
  const [dateRange, setDateRange] = useState({
    start: "",
    end: "",
  });
  const firstTableRef = useRef(null);
  const secondTableRef = useRef(null);
  const [firstTableWidth, setFirstTableWidth] = useState(0);

  useEffect(() => {
    // Get the width of the first table
    if (firstTableRef.current) {
      setFirstTableWidth(firstTableRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    getMessangerData();
  }, [
    sort,
    userType,
    isUnread,
    dateRange,
    companyId,
    country,
    userID,
    searchkeyword,
    page,
    countValue,
  ]);

  function getRestUrl() {
    let urlParams = {};
    if (dateRange.start)
      urlParams["filter[date_from]"] = moment(dateRange.start).format(
        "YYYY-MM-DD"
      );
    if (dateRange.end)
      urlParams["filter[date_to]"] = moment(dateRange.end).format("YYYY-MM-DD");
    if (country) urlParams["filter[country_id]"] = country;
    if (userID) urlParams["filter[user_id]"] = userID;
    if (companyId) urlParams["filter[subsidiary_id]"] = companyId;
    if (searchkeyword) urlParams["keyword"] = searchkeyword;
    if (sort) urlParams["sort"] = sort;
    if (countValue) urlParams["per_page"] = countValue;
    if (isUnread) urlParams["filter[unread]"] = 1;
    if (userType == userTypes.Private)
      urlParams["filter[user_type]"] = userType;
    if (page > 1) urlParams["page"] = page;
    const restUrl = new URLSearchParams(urlParams);
    return restUrl;
  }

  const getMessangerData = () => {
    _loaded(true);
    const apiUrl = getRestUrl();
    API.get(`${APP_URLS.MESSANGER}?${apiUrl}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success) {
          _data(resp.data);
          _totalPage(resp.data.list.last_page);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp?.data?.errors) {
          Object.keys(resp.data.errors).map(
            (error, index) => (error_message = resp.data.errors[error][0])
          );
        } else if (resp?.data?.data?.error) {
          error_message = resp.data.data.error;
        } else if (resp?.data?.error) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message || "An Unknown error occurred";
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const clearFilter = () => {
    _filters(true);
    _sort("");
    setDateRange({
      start: "",
      end: "",
    });
    _totalPage(0);
    setPage(1);
    _UserType(userTypes.Company);
    setCountry("");
    _userID("");
    _companyId("");
    _isUnread(false);
    _searchkeyword("");
  };

  const setsorting = (val) => {
    if (val != sort) {
      _sort(val);
      setPage(1);
      _totalPage(0);
    }
  };
  const setChangeValue = (func, item) => {
    func(item);
    _totalPage(0);
    setPage(1);
  };
  const handleUserType = (userTypeValue) => {
    _UserType(userTypeValue);
    _filters(true);
    if (userTypeValue == userTypes.Company) {
      _userID("");
    } else _companyId("");
  };

  const handleExtractReport = () => {
    const apiurl = getRestUrl();
    API.get(`${APP_URLS.MESSANGER}/download_messages_report?${apiurl}`, {
      responseType: "blob",
    })
      .then((res) => {
        const resp = res.data;
        if (resp !== null) {
          toast.success("Downloading. Please wait", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const content = res.headers["content-type"];
          const currentDate = moment().format("YYYY-MM-DD HH-mm-ss");
          const fileName = `${currentDate}_messages_report.xlsx`;
          download(resp, fileName, content);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message || "An Unknown error occurred";
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <MessangerWrapper>
      <div className="auctionMainContainer">
        <div className="row mt-4">
          <div className="col-md-4 offset-md-4 mb-2">
            <h1>Messenger</h1>
          </div>
        </div>
        <div className="justify-content-end mb-2 d-flex gap-2 mb-2 select-info align-items-center filter-wrapper">
          <div className="d-flex date-range-container gap-2">
            <input
              type="text"
              className="date date-range-container"
              id="start_date"
              placeholder="Start Date"
              onFocus={() =>
                (document.getElementById("start_date").type = "date")
              }
              onBlur={() =>
                dateRange.start === ""
                  ? (document.getElementById("start_date").type = "text")
                  : ""
              }
              value={dateRange.start}
              max={dateRange.end ? dateRange.end : day}
              onChange={(e) => {
                setDateRange((prev) => ({ ...prev, start: e.target.value }));
                setPage(1);
                _totalPage(0);
              }}
            />
            <input
              type="text"
              className="date date-range-container"
              id="end_date"
              placeholder="End Date"
              onFocus={() =>
                (document.getElementById("end_date").type = "date")
              }
              onBlur={() =>
                dateRange.end === ""
                  ? (document.getElementById("end_date").type = "text")
                  : ""
              }
              value={dateRange.end}
              max={day}
              onChange={(e) => {
                setDateRange((prev) => ({ ...prev, end: e.target.value }));
                setPage(1);
                _totalPage(0);
              }}
            />
          </div>
          <StaticFilter
            placeholder="User Type"
            data={userTypesData}
            handleChange={(e) => handleUserType(e.target.value)}
            value={userType}
          />
          <div
            className={`filter ${
              userType == userTypes.Private ? " block" : " none"
            }`}
          >
            <UserFilter
              _filters={_filters}
              filters={filters}
              setUser={(val) => setChangeValue(_userID, val)}
              user={userID}
            />
          </div>
          <div
            className={`filter ${
              userType == userTypes.Company ? " block" : " none"
            }`}
          >
            <SubsidiaryFilter
              _filters={_filters}
              filters={filters}
              setSub={(val) => setChangeValue(_companyId, val)}
              sub={companyId}
            />
          </div>
          <CountryFilter
            value={country}
            handleChange={(val) => setChangeValue(setCountry, val)}
          />
          <SearchKey
            onSearchClick={(search) => setChangeValue(_searchkeyword, search)}
            id="searchData"
            filters={filters}
            _filters={_filters}
            placeholder="Search by Title"
          />
          <div className="select-wrapper">
            <RecordCount
              onSearchClick={(search) => setChangeValue(_countValue, search)}
              id="pageCount"
              val={countValue}
              filters={filters}
              _filters={_filters}
            />
          </div>
          <ExtractReport
            downloadFunction={handleExtractReport}
            styles="extract-report ml-2"
            text=" Extract report"
          />
          <Tooltip title="Clear Filter" placement="bottom">
            <FaFilterIcon
              className="filter-icon"
              role="button"
              onClick={clearFilter}
            />
          </Tooltip>
        </div>
        <div className="d-flex justify-content-between mt-2 me-2 w-100">
          <span className="text-start">
            Total Records : <b>{data?.total_receivers || "0"}</b>
          </span>
          <div className="d-flex flex-row gap-2">
            <input
              type="checkbox"
              checked={isUnread}
              onChange={(e) => setChangeValue(_isUnread, e.target.checked)}
            />
            <span className="me-2">Unread</span>
          </div>
        </div>
      </div>
      <div className="tableContainer">
        <table ref={firstTableRef} className="auctionTable">
          <thead className="tableHead">
            <tr className="table-row">
              <th className="time-start">
                {userType == userTypes.Company ? (
                  `${userTypeName.DEALER} Name`
                ) : (
                  <span>
                    Full name
                    <br /> Username
                    <br /> Phone
                  </span>
                )}
              </th>
              <th className="time-start">
                Type of receiver
                <br />
                Country
              </th>
              <th className="time-start d-flex flex-column align-items-center">
                <span className="d-inline-block w-max">
                  Number of conversation
                  <Sorting
                    sortType="total_conversations"
                    sort={sort}
                    handleSort={setsorting}
                  />
                </span>
                <span className="d-inline-block w-max">
                  Number of message
                  <Sorting
                    sortType="total_messages"
                    sort={sort}
                    handleSort={setsorting}
                  />
                </span>
              </th>
              <th className="time-start d-flex flex-column align-items-center">
                <span className="d-inline-block w-max">
                  Number of unread conversation
                  <Sorting
                    sortType="unread_conversations"
                    sort={sort}
                    handleSort={setsorting}
                  />
                </span>
                <span className="d-inline-block w-max">
                  Number of unread message
                  <Sorting
                    sortType="unread_messages"
                    sort={sort}
                    handleSort={setsorting}
                  />
                </span>
              </th>
              <th className="time-start">
                <span className="d-inline-block w-max">
                  Avg. time to answer
                  <Sorting
                    sortType="avg_time"
                    sort={sort}
                    handleSort={setsorting}
                  />
                </span>
              </th>
              <th className="time-start">
                <span className="d-inline-block w-max">
                  Number of Messages Sent
                  <Sorting
                    sortType="total_messages_sent "
                    sort={sort}
                    handleSort={setsorting}
                  />
                </span>
              </th>
              <th className="time-start">
                <span className="d-inline-block w-max">
                  Number of Messages Not Answered
                  <Sorting
                    sortType="total_messages_not_answer"
                    sort={sort}
                    handleSort={setsorting}
                  />
                </span>
              </th>
              <th className="time-start">
                <span className="d-inline-block w-max">
                  Date of First Message Sent
                  <Sorting
                    sortType="date_first_message_sent"
                    sort={sort}
                    handleSort={setsorting}
                  />
                </span>
              </th>
              <th className="time-start">
                <span className="d-inline-block w-max">
                  Date of Last Message Sent
                  <Sorting
                    sortType="date_last_message_sent"
                    sort={sort}
                    handleSort={setsorting}
                  />
                </span>
              </th>
            </tr>
          </thead>
          {loaded ? (
            <div className="d-flex justify-content-center three-dot">
              <ThreeDots
                height="150"
                width="150"
                radius="9"
                color="#637df4"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
          ) : (
            <tbody>
              {data?.list?.data && data.list.data.length > 0 ? (
                data.list.data.map((item) => {
                  return (
                    <tr key={item.id} className="table-row">
                      <td>
                        <div className="d-flex flex-column">
                          {userType == userTypes.Dealer ? (
                            <div>
                              <a
                                target="_blank"
                                href={`/company-details/${item.id}`}
                                className="link-none"
                              >
                                <span>
                                  {item.name !== null
                                    ? item.name
                                        .replace("_", " ")
                                        .charAt(0)
                                        .toUpperCase() +
                                      item.name.replace("_", " ").slice(1)
                                    : "" || ""}
                                </span>
                              </a>
                              <br />
                              <a
                                target="_blank"
                                href={`/company-details/${item.id}`}
                                className="link-none"
                              >
                                <span>{item?.user_name || ""}</span>
                              </a>
                              <br />
                              <span>
                                {item?.phone
                                  ? `${item?.limited_address?.prefix_call}-${item.phone}`
                                  : null}
                              </span>
                            </div>
                          ) : (
                            <div>
                              <a
                                target="_blank"
                                href={`detail-member/${item.id}`}
                                className="link-none"
                              >
                                <span>
                                  {item.name !== null
                                    ? item.name
                                        .replace("_", " ")
                                        .charAt(0)
                                        .toUpperCase() +
                                      item.name.replace("_", " ").slice(1)
                                    : "" || ""}
                                </span>
                              </a>
                              <br />
                              <a
                                target="_blank"
                                href={`detail-member/${item.id}`}
                                className="link-none"
                              >
                                <span>{item?.user_name || ""}</span>
                              </a>
                              <br />
                              <span>
                                {item?.phone
                                  ? `${item?.limited_address?.prefix_call}-${item.phone}`
                                  : null}
                              </span>
                            </div>
                          )}
                        </div>
                      </td>
                      <td>
                        {item?.sub ? userTypeName.DEALER : userTypeName.PRIVATE}{" "}
                        <br />
                        {item.limited_address.flag_thumbnail ? (
                          <img
                            src={item.limited_address.flag_thumbnail}
                            title={item.limited_address.country}
                            className="countryflag"
                            alt="flag"
                          />
                        ) : (
                          "-"
                        )}{" "}
                      </td>
                      <td>
                        {item?.total_conversations || "-"} <br />
                        {item?.total_messages || "-"}
                      </td>
                      <td>
                        {item?.unread_conversations || "0"} <br />
                        {item?.unread_messages || "0"}
                      </td>
                      <td>
                        {item?.avg_time ? formatTime(item?.avg_time) : "-"}
                      </td>
                      <td>{item?.total_messages_sent || "-"}</td>
                      <td>{item?.total_messages_not_answer || "-"}</td>
                      <td>{item?.date_first_message_sent || "-"}</td>
                      <td>{item?.date_last_message_sent || "-"}</td>
                    </tr>
                  );
                })
              ) : (
                <NoRecord />
              )}
            </tbody>
          )}
        </table>

        {/* total of all records */}
        {!loaded ? (
          <table
            ref={secondTableRef}
            className="auctionTable reviewTable"
            style={{ minWidth: `${firstTableWidth}px` }}
          >
            <thead className="tableHead">
              <tr className="table-row table-total">
                <th className="time-start"> Total: </th>
                <th
                  title={userType == userTypes.Private ? "Private" : "Dealer"}
                  className="time-start"
                >
                  {data?.total_receivers || "0"}
                </th>
                <th className="time-start d-flex flex-column align-items-center">
                  <span
                    title="Number of conversation"
                    className="d-inline-block w-max"
                  >
                    {data?.total_conversations || "-"}
                  </span>
                  <span
                    title="Number of message"
                    className="d-inline-block w-max"
                  >
                    {data?.total_messages || "-"}
                  </span>
                </th>
                <th className="time-start d-flex flex-column align-items-center">
                  <span
                    title="Number of unread conversation"
                    className="d-inline-block w-max"
                  >
                    {data?.total_unread_conversations || "-"}
                  </span>
                  <span
                    title="Number of unread message"
                    className="d-inline-block w-max"
                  >
                    {data?.total_unread_messages || "-"}
                  </span>
                </th>
                <th title="Avg. time to answer" className="time-start">
                  -
                </th>
                <th title="Number of Messages Sent" className="time-start">
                  {data?.total_messages_sent || "-"}
                </th>
                <th
                  title="Number of Messages Not Answered"
                  className="time-start"
                >
                  {data?.total_messages_not_answer || "-"}
                </th>
                <th title="Date of First Message Sent" className="time-start">
                  -
                </th>
                <th title="Date of Last Message Sent" className="time-start">
                  -
                </th>
              </tr>
            </thead>
          </table>
        ) : null}
      </div>
      <div>
        {totalPage > 1 ? (
          <Pagination
            totalPages={totalPage}
            page={page}
            key={totalPage}
            onPageClick={(page) => setPage(page + 1)}
          />
        ) : (
          ""
        )}
      </div>
    </MessangerWrapper>
  );
};

export default Messanger;
